import { REQUEST, RESPONSE_HANDLER } from 'utilities';

/**
 * Create a new payment
 * @param token
 * @param email
 * @param data
 * @returns {Promise<*>}
 * @constructor
 */
export const CREATE_PAYMENT = async (token, email, data) => {
  const CREATE = await REQUEST.post('payment_sessions', {
    headers: {
      email,
      token,
    },
    json: {
      payment_session: {
        ...data,
      },
    },
  });
  return RESPONSE_HANDLER(CREATE);
};

/**
 * Update a payment
 * @param token
 * @param email
 * @param data
 * @returns {Promise<*>}
 * @constructor
 */
export const UPDATE_PAYMENT = async (paymentID, token, email, data) => {
  const CREATE = await REQUEST.put(`payment_sessions/${paymentID}`, {
    headers: {
      email,
      token,
    },
    json: {
      payment_session: {
        ...data,
      },
    },
  });
  return RESPONSE_HANDLER(CREATE);
};
