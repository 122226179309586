import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import queryString from 'query-string';
import { PAGES } from 'config';
import { BookingHeader } from 'layouts';

import { mapStateToProps } from 'utilities';
import { BookingLayout } from 'containers';
import { UPDATE_PAYMENT } from 'actions/payment';

class BookingsPaymentErrorPage extends PureComponent {
  componentDidMount() {
    const { booking } = this.props;
    if (booking.currentBooking) {
      this.updateBookingPayment();
    }
  }

  componentDidUpdate(prevProps) {
    const { booking } = this.props;
    // if we have now got a booking
    if (!prevProps.booking.currentBooking && booking.currentBooking) {
      this.updateBookingPayment();
    }
  }

  updateBookingPayment() {
    const { user, booking } = this.props;
    const PARSED = queryString.parse(window.location.search);
    if (PARSED.paymentId) {
      (async () => {
        // 1. update payment
        await UPDATE_PAYMENT(PARSED.paymentId, user.session.token, user.session.email, {
          booking_id: booking.currentBooking.id,
          response: PARSED.result || '',
        });
      })();
    }
  }

  render() {
    const { booking } = this.props;
    return (
      <BookingLayout page="bookings/payments/error">
        <div className="l-two-col">
          <BookingHeader
            title="Payment Error"
            subTitle="You're previous payment failed, your credit card was not charged."
          />
          <div>
            {
              booking.currentBooking
                ? (
                  <div>
                    <Link
                      to={`${PAGES.BOOKINGS_PAYMENT.PATH}?bookingId=${booking.currentBooking.id}&tour=${booking.currentBooking.tour_slug}`}
                      className="c-button c-button--wide c-button--primary"
                    >Back to payments
                    </Link>
                  </div>
                ) : <h4>Loading...</h4>
            }
          </div>
        </div>
      </BookingLayout>
    );
  }
}

BookingsPaymentErrorPage.propTypes = {
  user: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(BookingsPaymentErrorPage);
